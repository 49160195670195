import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import { useRef } from 'react'

import { Markdown } from '../../../../components/markdown'
import type { BentoPieceRectangleFragment } from '../../../../graphql/queries/GetBentoPieceRectangle.graphql'
import { usePersonalizations } from '../../../../lib/hooks/usePersonalizations'
import { useWindowDimensions } from '../../../../lib/hooks/useWindowDimensions'
import { renderTemplate } from '../../../../lib/utils/text'
import variables from '../../../../styles/variables.module.scss'
import { ActionButton } from '../../../action-button'
import styles from './bento-rectangle.module.scss'

export const BentoRectangle: FunctionComponent<Props> = ({
    vertical = false,
    headingMd,
    headingColor = 'white',
    copy,
    copyColor = 'white',
    showFdicVisaImage = false,
    showWinnersBadge = false,
    button,
    backgroundType = 'color',
    backgroundColor = 'darkGray',
    image = null,
    backgroundImage = null,
    backgroundImageMobile = null,
    headingImage = null,
}) => {
    const mobileBreakpoint = 1024
    const personalizations = usePersonalizations()
    const { width } = useWindowDimensions()
    const containerRef = useRef(null)

    function getBackgroundStyle() {
        if (backgroundType === 'video') return
        if (backgroundType === 'image') {
            if (!headingMd && !copy) {
                return {
                    backgroundImage: getBackgroundImageUrl(),
                    minHeight: '455px',
                }
            }
            return { backgroundImage: getBackgroundImageUrl() }
        }

        return { backgroundColor: variables[backgroundColor] }
    }

    function getBackgroundImageUrl() {
        let bgImageUrl = `url(${backgroundImage?.url})`

        if (width < mobileBreakpoint && backgroundImageMobile?.url) {
            bgImageUrl = `url(${backgroundImageMobile?.url})`
        }

        return bgImageUrl
    }

    function getHeadingStyle() {
        return {
            color: headingColor,
        }
    }

    return (
        <div
            className={styles.boxOne}
            style={getBackgroundStyle()}
            ref={containerRef}
        >
            <div
                className={`${styles.boxOneContent} ${
                    vertical ? styles.vertical : ''
                }`}
            >
                {showWinnersBadge && (
                    <div className={styles.winnersBadge}>
                        <Image
                            className={styles.badge}
                            src='https://images.ctfassets.net/bkmkssdu4exw/4qAdUBJ27n55bCTEzK8c2J/4131a3bb439abec1554b6440916b9f06/winners-badge-o.png'
                            width={width > mobileBreakpoint ? 200 : 100}
                            height={width > mobileBreakpoint ? 200 : 100}
                            alt='Winners Badge'
                        />
                    </div>
                )}

                <div
                    className={`${styles.boxOneCopy} ${
                        vertical ? styles.vertical : ''
                    }`}
                >
                    <div
                        className={`${styles.heading} ${
                            headingColor === 'gradient'
                                ? styles.headinggradient
                                : ''
                        }`}
                        style={getHeadingStyle()}
                    >
                        <Markdown
                            typeStyle={{
                                _: 'heading-lg',
                                md: 'heading-md',
                            }}
                            markdown={renderTemplate(
                                headingMd,
                                personalizations
                            )}
                        />
                    </div>

                    {headingImage && (
                        <div className={styles.headingImage}>
                            <Image
                                className={`${styles.image}`}
                                src={headingImage.url ?? ''}
                                width={headingImage.width}
                                height={headingImage.height}
                                alt={headingImage.alt ?? ''}
                                priority
                            />
                        </div>
                    )}

                    {copy && (
                        <div
                            className={`${styles.subtitle} ${
                                vertical ? styles.vertical : ''
                            }`}
                        >
                            <Markdown
                                typeStyle={{ _: 'body-lg', md: 'body-md' }}
                                markdown={renderTemplate(
                                    copy,
                                    personalizations
                                )}
                                color={copyColor}
                            />
                        </div>
                    )}

                    {button && (
                        <div className={styles.buttons}>
                            {!!button && (
                                <ActionButton
                                    preset={button?.preset}
                                    {...button}
                                />
                            )}
                        </div>
                    )}

                    {showFdicVisaImage && (
                        <div className={styles.buttons}>
                            <Image
                                className={styles.media}
                                src='https://images.ctfassets.net/bkmkssdu4exw/51POpo2q8ty3wbaIBG5HPU/09ccc31c832fc325def14466d7e703a4/FDICVISA.png'
                                width={218}
                                height={35}
                                alt='FDIC VISA Insured'
                            />
                        </div>
                    )}
                </div>

                <div
                    className={`${styles.media} ${
                        vertical ? styles.vertical : ''
                    }`}
                >
                    <div
                        className={`${styles.imageContainer} ${
                            vertical ? styles.vertical : ''
                        }`}
                    >
                        {image && (
                            <Image
                                className={styles.image}
                                src={image.url ?? ''}
                                width={image.width}
                                height={image.height}
                                alt={image.alt ?? ''}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BentoRectangle

type Props = Omit<BentoPieceRectangleFragment, 'sys' | 'type'> & {
    index?: number
    vertical?: boolean
}
